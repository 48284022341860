<template>
  <div class="wrap">
   <!-- <div class="box-top">
       <el-button type="primary" icon="el-icon-circle-plus-outline" size="small" @click="addActivity">新增</el-button>
       <el-input v-model="mess" placeholder="请输入活动主题查询" @keydown.enter.native="Search" clearable>
         <i slot="prefix" class="el-input__icon el-icon-search" @click="Search"></i>
       </el-input>
    </div>  -->
    <!-- 列表 -->
    <commonTable ref="commonTable" :dataSource="tableData" :columnsList="columns" :total="total" @currentChange="currentChange" @sizeChange="sizeChange">
      <template slot="action" slot-scope="{record}">
        <el-button @click="editButton(record)" type="text" style="color: #00788b;">审核</el-button>
        <!-- <el-divider direction="vertical"></el-divider> -->
        <!-- <el-popconfirm title="确定删除吗？" @confirm="confirm(record)">
          <el-button slot="reference" type="text" style="color: #b51c1b;">删除</el-button>
        </el-popconfirm> -->
      </template>
    </commonTable>

    <!-- 弹窗 -->
    <el-dialog
      title="文章详情"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose" center :close-on-press-escape="false" :close-on-click-modal="false">
        <edit-article v-if="dialogVisible" ref="activity" :oldForm="form"></edit-article>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" :size="btnSize">取 消</el-button>
        <el-button type="primary" @click="submit" :size="btnSize">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
/** import { listActivity, addActivity, deleteActivity, updateActivity } from '../../api/publicActive'; // 研学发布接口**/
// import { getList, examine } from '../../api/articleManage'; // 书友文章管理的接口
import { articleGetList, articleQueryById, articleCheck } from '@/api/articleManage';
import editArticle from './editArticle.vue';
import commonTable from '../../components/commonTable.vue';
import { myMixins } from '@/mixins/myMixins';
export default {
  name: 'activityPublic',
  mixins: [myMixins],
  data() {
    return {
      id: JSON.parse(sessionStorage.getItem('userInfo')).id || '',
      mess: '',
      total: 0,
      title: '',
      pageNo: 1,
      pageSize: 10,
      btnSize: 'mini',
      currentPage4: 1,
      dialogVisible: false,
      tableData: [],
      form: {},
      columns: [
        {
          title: '文章标题',
          key: 'title',
          align: 'center'
        },
        {
          title: '发布者',
          key: 'nickname',
          align: 'center',
        },
        {
          title: '发布时间',
          key: 'createTime',
          align: 'center',
        },
        {
          title: '发布内容',
          key: 'info',
          align: 'center',
        },
        {
          title: '类型',
          key: 'type',
          align: 'center',
        },
        {
          title: '操作',
          key: 'action',
          align: 'center',
          scopedSlots: {
            columnSlot: 'action'
          }
        },
      ]
    }
  },
  components: {
    editArticle,
    commonTable
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      let data = {
        // title: this.mess||undefined,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }
      articleGetList(data).then(res => {
        console.log(res);
        if(res.success) {
          if(res.result.data.length == 0 && res.result.total != 0) {
            this.pageNo = res.result.pages;
            this.loadData();
          }else {
            this.tableData = res.result.data;
            this.total = res.result.total;
            // this.pageNo = res.result.current;
            // this.pageSize = res.result.size;
            this.pageNo = 1;
            this.pageSize = 10;
          }
          this.$nextTick(() => {
            this.$refs.commonTable.$refs.table.doLayout();
          })
        }
      })
    },
    Search() {
      this.pageNo = 1;
      this.loadData();
    },
    submit() {
      this.$refs.activity.getdatas();
      this.$refs.activity.$refs.form.validate((valid) => {
        let formInfo = this.$refs.activity.form
        console.log(formInfo);
        let param = JSON.parse(JSON.stringify(formInfo));
        if(!valid) {
          return 
        }else{
          let request = null;
          if(formInfo.id) {
            request = articleCheck(param);
          }
          else {
            param.id = this.id//用户id
            request = addActivity(param)
          }
          request.then(res => {
            if(res.success) {
              this.$message.success(res.message);
              this.dialogVisible = false;
              this.loadData();
            }else {
              this.$message.error(res.message);
            }
          })
        }
      })
    },
    dateTimeToString(date) {
      var y = date.getFullYear();
      var M = date.getMonth() + 1;
      var d = date.getDate();
      var H = date.getHours();
      var m = date.getMinutes();
      var s = date.getSeconds();
      return y + '-' + (M < 10 ? ('0' + M) : M) + '-' + (d < 10 ? ('0' + d) : d) + " " + (H < 10 ? ('0' + H) : H) + ":" + (m < 10 ? ('0' + m) : m) + ":" + (s < 10 ? ('0' + s) : s);
    },
    handleClose() {
      this.dialogVisible = false;
    },
    addActivity() {
      this.form = {};
      this.dialogVisible = true;
      this.title = '新增活动';
    },
    editButton(e) {
      this.form = e;
      this.dialogVisible = true;
      this.title = '编辑活动';
    },
    del(e) {
      deleteActivity({id: e.id}).then(res => {
        if(res.success) {
          this.$message.success(res.message);
          this.loadData();
        }else {
          this.$message.error(res.message);
        }
      })
    },
    confirm(e) {
      this.del(e);
    }
  }
}
</script>

<style scoped lang="less">
  @import '../../assets/css/commonMenu.less';
</style>