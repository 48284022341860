import { getAction,postAction } from "../utils/request"

// 列表接口
export function articleGetList(data) {
  return getAction('/web/bookClub/list', data);
} 

// 文章详情接口
export function articleQueryById(data) {
  return getAction('/web/bookClub/queryById', data);
}

// 审核文章接口
export function articleCheck(data) {
  return postAction('/web/bookClub/check', data);
}

