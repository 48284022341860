<template>
  <div>
    <el-form ref="form" :model="form" :rules="rules" label-width="120px">
      <el-form-item label="文章标题：" prop="title">
        <el-input v-model="form.title" placeholder="请输入活动标题" disabled readonly></el-input>
      </el-form-item>
      <el-form-item label="发布者：" prop="nickname">
        <el-input v-model="form.nickname" placeholder="请输入发布者" disabled ></el-input>
      </el-form-item>
      <el-form-item label="发布时间：" prop="createTime">
        <el-date-picker
          v-model="form.createTime"
          disabled
          type="datetime"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="发布时间" style="width: 100%;">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="文章内容：" prop="info">
        <el-input :autosize="{ minRows: 1, maxRows: 3}" type="textarea" v-model="form.info" disabled></el-input>
      </el-form-item>
      <el-form-item label="图片：" prop="picture">
        <img style="width: 200px;height: 200px" :src="form.picture" alt="">
      </el-form-item>
      <el-form-item label="是否发布：" prop="info">
        <el-select v-model="form.pass" @change="passChange" placeholder="请选择">
          <el-option label="发布" value="1"></el-option>
          <el-option label="不发布" value="2"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { infoActivity } from '../../api/publicActive'
import { articleQueryById } from '@/api/articleManage';
export default {
  name: 'userEdit',
  props: ['oldForm'],
  data() {
    return {
      // actionUrl: window._CONFIG['DOMIANURL'],
      dialogImageUrl: '',
      dialogVisible: false,
      uploadURL: baseURL + '/web/file/uploadImage',
      headers: {
        'X-Access-Token': sessionStorage.getItem('token'),
      },
      rules: {
        title: [
            { required: false, message: '请输入活动标题', trigger: 'blur' },
          ],
        nickname: [{ required: false, message: '请输入发布者', trigger: 'blur' }],
        createTime: [{ required: false, message: '请输入发布时间', trigger: 'blur' }],
        info: [{ required: true, message: '请输入文章内容', trigger: 'blur' }],
        picture: [{ required: false, message: '请上传图片', trigger: 'blur' }],
        pass: [{ required: true, message: '是否发布', trigger: 'blur' }],
      },
      form: {
        title: '',
        nickname: '',
        createTime: '',
        info: '',
        picture: '',
        pass: '',
      },
      files:[],
      fileList: [
        // {name:'ffff',url:'http://192.168.2.110:8080/yhs/upload/images/cc_1664262535605.png'}
        ],
    }
  },
  mounted() {
    this.form = JSON.parse(JSON.stringify(this.oldForm));
    console.log(this.form,baseURL);
    if(this.form.id) {
      this.fileList = [];
      articleQueryById({id:this.form.id}).then(res=>{
        console.log(res);
        if(res.success) {
          this.form = res.result;
          this.form.picture = baseURL + res.result.picture;
          // let list = res.result.picture.split(',');
          // for(let i = 0;i<list.length;i++) {
          //   let str = list[i];
          //   let name = str.substring(str.lastIndexOf('/')+1);
          //   name = name.substring(0,name.indexOf('_'));
          //   let obj = {name,url:baseURL+str,fileUrl:str}
          //   this.fileList.push(obj);
          // }
        }
      })
    }
  },
  methods: {
    handleRemove(file, fileList) {
      if(file.fileUrl) {
        for(let i = 0;i<this.fileList.length;i++) {
          if(this.fileList[i].fileUrl === file.fileUrl) {
            this.fileList.splice(i,1);
            break;
          }
        }
      }else{
        if(file.status == 'success' && file.response && file.response.result) {
          for(let i = 0;i< this.files.length;i++) {
            if(file.response.result == this.files[i]) {
              this.files.splice(i,1);
            }
          }
        }
      }
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    imgChange(response, file) {
      if(response.success) {
        this.$message.success('上传成功! ');
        this.fileList.push({
          name:file.name,
          url: baseURL + response.result,
          fileUrl: response.result
        });
        this.form.picture =  'true';
      }else {
        this.$message.error(res.response.message);
      }
    },
    beforeAvatarUpload(file) {
      const types = file.name.substring(file.name.lastIndexOf('.')+1);
      const whiteList = ['jpg','jpeg','png','gif','JPG','JPEG'];
      if(whiteList.indexOf(types) == -1) {
        this.$message.error('请上传正确图片格式');
        return false
      }
    },
    passChange(val){
        console.log(val);
        this.form.status = val;
    },
    getdatas(){
      let list = [];
      for(let item of this.fileList) {
        list.push(item.fileUrl);
      }
      for(let item of this.files) {
        list.push(item);
      }
      this.form.picture = list.join(',');
    }
  }
}
</script>

<style scoped lang="less">
  .el-select{
    width: 100%;
  }
  /deep/ .el-upload--picture-card {
    width: 140px;
    height: 140px;
    line-height: 140px;
  }
  /deep/ .el-upload-list__item {
    width: 140px;
    height: 140px;
  }
  /deep/ .v-model{
    opacity: 0;
  }
  /deep/ .el-textarea__inner {
    max-height: 130px;
  }
</style>